<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn text v-on="on">
        Contact
      </v-btn>
    </template>

    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>Contact Us</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="color1Text" text icon @click.stop="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="text-center title">
        Questions regarding the tournament?
        <v-btn color="color3 color3Text--text" :href="`mailto:${tournament.organization.support}`">
          Contact {{tournament.organization.name}}
        </v-btn>
      </v-card-text>
      <v-card-text class="text-center title">
        <div>Questions regarding the site?</div>
        <v-btn color="color3--text color3Text" href="mailto:Support@VolleyballLife.com">
          Contact Volleyball Life
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    ...mapGetters(['tournament'])
  }
}
</script>

<style>

</style>
